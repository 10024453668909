import { mapGetters } from "vuex";

export function themeColorFn(
  options = {
    themeConfigPage: undefined,
    themeConfig: undefined,
  }
) {
  const { themeConfigPage, themeConfig } = options;
  return {
    data: () => ({
      themeConfigPage,
    }),
    computed: {
      ...mapGetters({
        _themeConfig: "liffGeneral/themeConfig",
      }),
      themeConfig() {
        return themeConfig || this._themeConfig;
      },
      colorObject() {
        const config =
          (this.themeConfigPage && this.themeConfig[this.themeConfigPage]) || this.themeConfig?.common;
        const commonConfig = this.themeConfig?.common;

        const checkAvailable = (key, defaultValue) => {
          if (config?.[key] !== undefined && config?.[key] !== "#" && config?.[key] !== "") {
            return config?.[key];
          }
          if (commonConfig?.[key] !== undefined && commonConfig?.[key] !== "#" && commonConfig?.[key] !== "") {
            return commonConfig?.[key];
          }
          return defaultValue;
        };

        return {
          // header 表頭底色
          "--liff-header-color": checkAvailable("header_color", "#6699cc"),
          // header 表頭字色
          "--liff-header-text-color": checkAvailable(
            "header_text_color",
            "#ffffff"
          ),
          // header 表頭圖片背影顏色
          "--liff-header-image-bg-color": checkAvailable(
            "header_image_bg_color",
            null
          ),
          "--liff-button-color": checkAvailable("button_color", "#6699cc"),
          "--liff-button-text-color": checkAvailable(
            "button_text_color",
            "#ffffff"
          ),
          "--liff-tab-color": checkAvailable("tab_color", "#6699cc"),
          "--liff-tab_inactive_color": checkAvailable("tab_inactive_color", "#6699cc"),
          // 主字色
          "--liff-primary_text_color": checkAvailable(
            "primary_text_color",
            "#2c2c2e"
          ),
          // 第二字色
          "--liff-secondary_text_color": checkAvailable(
            "secondary_text_color",
            "#636366"
          ),
          // 整體底色
          "--liff-layout_bg_color": checkAvailable("layout_bg_color", "#f2f2f7"),
          // 頁面底色
          "--liff-content_bg_color": checkAvailable(
            "content_bg_color",
            "#ffffff"
          ),




          "--s-primary": checkAvailable("primary_color", "#6699cc"),
          "--background-color": checkAvailable("background_color", "#186a6b"),
          "--primary-color": checkAvailable("primary_color", "#186a6b"),

          "--liff-card_name_text_color": checkAvailable("primary_text_color", "#2c2c2e"),
          //"--liff-card_bg_top_color": checkAvailable("primary_color", "#6699cc"),
          //"--liff-card_bg_bottom_color": checkAvailable("content_bg_color", "#ffffff"),
        };
      },
    },
  };
}

export default themeColorFn();
